module.exports = [{
      plugin: require('/home/martijn/workspace/moncau/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/martijn/workspace/moncau/src/components/layout.js"}},
    },{
      plugin: require('/home/martijn/workspace/moncau/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/martijn/workspace/moncau/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
