// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/home/martijn/workspace/moncau/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("/home/martijn/workspace/moncau/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assortiment-mdx": () => import("/home/martijn/workspace/moncau/src/pages/assortiment.mdx" /* webpackChunkName: "component---src-pages-assortiment-mdx" */),
  "component---src-pages-certificats-mdx": () => import("/home/martijn/workspace/moncau/src/pages/certificats.mdx" /* webpackChunkName: "component---src-pages-certificats-mdx" */),
  "component---src-pages-sobre-nosaltres-mdx": () => import("/home/martijn/workspace/moncau/src/pages/sobre-nosaltres.mdx" /* webpackChunkName: "component---src-pages-sobre-nosaltres-mdx" */),
  "component---src-pages-tenda-mdx": () => import("/home/martijn/workspace/moncau/src/pages/tenda.mdx" /* webpackChunkName: "component---src-pages-tenda-mdx" */),
  "component---src-pages-index-mdx": () => import("/home/martijn/workspace/moncau/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-contacte-mdx": () => import("/home/martijn/workspace/moncau/src/pages/contacte.mdx" /* webpackChunkName: "component---src-pages-contacte-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/martijn/workspace/moncau/.cache/data.json")

